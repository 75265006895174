body.old {
    color: #fffccc;
    font-size: 12pt;
    background-color:#000000
}

body.old a {
    color:#ffd700
}

body.old .logo {
    float:left
}

body.old .title {
    padding:25px 0px 0px 100px
}

body.old .content {
    clear:both
}

body.old .left {
    float: left;
    width:170px
}

body.old .center {
    margin-left:175px
}

body.old .footer {
    font-size: 8pt;
    text-align: center;
    clear:both
}

body.old ul {
    margin-left:-25px
}

body.old .basket_li {
    font-size:10pt
}

body.old .ui-tabs-hide {
    display:none
}

body.old .ui-tabs-nav {
    margin: 0;
    padding: 0;
    list-style:none
}

body.old .ui-tabs-nav li {
    padding: 0;
    margin: 0 5px 0 0;
    float:left
}

body.old .ui-tabs-nav a {
    text-decoration: none;
    display: block;
    padding: 5px 15px 3px 15px;
    border: 1px solid #999;
    border-bottom: none;
    font-size:1.5em
}

body.old .ui-tabs-selected a {
    background-color:#ff36a4
}

body.old .ui-tabs-panel {
    clear: left;
    border: 1px solid #ffc;
}

body.old div#menu p.top {
    float: right;
    display: inline;
    font-size: smaller;
    padding-right:15px
}

body.old div#menu {
    width:185px
}

body.old div#menu a, body.old div#menu p {
    color:white
}

body.old div#menu a {
    text-decoration:none
}

body.old div#menu ul {
    list-style-type: none;
    padding: 0px;
    margin:0px
}

body.old div#menu p {
    margin-bottom: 0px;
    padding-bottom:0px
}

body.old div#menu div.search p, body.old div#menu div.search ul, body.old div#menu div.search form, body.old div#menu div.newsletter p, body.old div#menu div.newsletter ul, body.old div#menu div.newsletter form {
    padding: 0px;
    margin: 0px;
    font-weight:normal
}

body.old div#menu div.search, body.old div#menu div.newsletter {
    margin-top:10px
}

body.old div#menu p, body.old div#menu div.search p.heading, body.old div#menu p, body.old div#menu div.newsletter p.heading {
    font-weight: bold;
    padding-left:0px
}

body.old div#menu li, body.old div#menu div.search p, body.old div#menu li, body.old div#menu div.newsletter p {
    padding-left:10px
}

body.old div#menu a:visited {
    text-decoration:underline
}

body.old div#menu a:hover {
    color: gold;
    text-decoration:underline
}

body.old div#menu a:active {
    color:blue
}

body.old div#menu abbr {
    border-bottom:none
}

body.old {
    background-color: #fff;
    color: #333;
    font-family: verdana, arial, helvetica, sans-serif;
    font-size: 13px;
    line-height:18px
}

body.old p, body.old ol, body.old ul, body.old td {
    font-family: verdana, arial, helvetica, sans-serif;
    font-size: 13px;
    line-height:18px
}

body.old pre {
    background-color: #eee;
    padding: 10px;
    font-size:11px
}

body.old a {
    color:#000
}

body.old a:visited {
    color:#666
}

body.old a:hover {
    color: #fff;
    background-color:#000
}

body.old div.field, body.old div.actions {
    margin-bottom:10px
}

body.old #notice {
    color:green
}

body.old .field_with_errors {
    padding: 2px;
    background-color: red;
    display:table
}

body.old #error_explanation {
    width: 450px;
    border: 2px solid red;
    padding: 7px;
    padding-bottom: 0;
    margin-bottom: 20px;
}

body.old #error_explanation h2 {
    text-align: left;
    font-weight: bold;
    padding: 5px 5px 5px 15px;
    font-size: 12px;
    margin: -7px;
    margin-bottom: 0px;
    background-color: #c00;
    color:#fff
}

body.old #error_explanation ul li {
    font-size: 12px;
    list-style:square
}

body.old {
    font: 12px;
    font-weight: normal;
    color: #fffccc;
    background:#000
}

body.old a {
    color: #ffd700
}

body.old .pagination {
    list-style: none;
    display: flex; 
}
  
body.old .page-link {
    position: relative;
    display: block;
    padding: .5rem .15rem;
    margin-left: -1px;
    line-height: 1.25;
}
  
  